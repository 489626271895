@import '../../../../common/vars.scss';
.refineBox {
padding:10px;

 button {
  background:$primaryColor;
border-color: #fff;

border-color: #fff;
font-weight: 500;
box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;

     transition: background-color 0.5s ease;
&:active{

  background:$primaryColor;
border-color: #fff;

border-color: #fff;
font-weight: 500;
box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;

 }

&:focus{

  background:$primaryColor;
border-color: #fff;
border-color: #fff;
font-weight: 500;
box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;

 }
  
  &:hover {

border-color: #fff;
color:#fff;
background:$secondaryColor;
  }


}
.message {
font-size: 1em;
display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    // cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;

& .error {
color:$primaryColor;
}
}
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {

box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;
}
