@import "../../common/vars.scss";
.wrapper {
  position: relative;
.auto-suggest {
position: absolute;
background-color: $searchHeading;
width: 100%;
list-style-type:none;
margin:1px;
padding-left:9px;
width:96%;
border-radius: 8px;
 li {
color:#ffffff;
&:hover {
background-color:$searchHeading;
color:$background;
font-weight:bold;
cursor:pointer;
}

 }
};
}
