@import "../../../common/vars.scss";

.history {
  text-align: center;
  .title {

    margin-top:40px;
    margin-bottom:14px;
  }
  .number {
    cursor:pointer;
   padding:14px;
   margin:1px;
  background-color: #e9ecef;

     transition: background-color 0.5s ease;
   &:hover {
   background-color: $primaryColor;
   color:$background;
   }
  };

   .selected {
      background-color: $primaryColor;
      color:$background;
   }
  .preview {

    margin-top:10px;
    img {
    width: 100%;
  cursor: pointer;
    }
 
  }

}
