@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import "../../common/vars.scss";

.vertical-center {
  // min-height: 9%;  /* Fallback for browsers do NOT support vh unit */
  // min-height: 9vh; /* These two lines are counted as one :-)       */

  // display: flex;
  // align-items: center;
   min-height : calc(100vh - 10rem);
}


.break {
  flex-basis: 100%;
  height: 0;
}
.main {

box-shadow:  8px 8px 16px #dfdedd,
             -8px -8px 16px #ffffff;
margin-top:4%;
button {
background:$primaryColor;
border-color: $primaryColor;

&:active{
   background:$primaryColor;

border-color: $primaryColor;
 }

&:focus{
   background:$primaryColor;

border-color: $primaryColor;
 }

&:hover {
  
   background:$secondaryColor;

border-color: $primaryColor;
} 
}

.glide-down {
transition: margin 700ms;
}
.input-group-text.search-button {
    cursor:pointer;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding:12px;
     transition: background-color 0.5s ease;
  &:hover {
  background:$primaryColor;
  color:$background;

  }

}

.clear-button {
    display:inline-block;
    cursor:pointer;
     transition: background-color 0.5s ease;
     transition: display 0.5s ease;
     background:$background;

}

.hidden {display:none}
input {
border-right: 0;
  border-radius: 14px;
  margin-bottom: 0;
// box-shadow:  8px 8px 16px #dfdeddu,
//              -8px -8px 16px #ffffff;
&:focus {
   box-shadow: 0 0 0 0; // $primaryColor;
  border-color:#ced4da;
  color: $primaryColor;
}
}
}
