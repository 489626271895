@import '../../../common/vars.scss';

.topPanel {

.logo {
font-size: 22px;
color: $searchHeading;
  font-family: 'Titillium Web', sans-serif;
  margin-top: -22px;
  margin-bottom:-10px;
  cursor: pointer;
}
 button {
background:$primaryColor;
border-color: $primaryColor;


&:active{
   background:$primaryColor;
border-color: $primaryColor;
 }

&:focus{
   background:$primaryColor;
border-color: $primaryColor;
 }

&:hover {
  
   background:$secondaryColor;

border-color: $primaryColor;
} 
}
input {
border-right: 0;
  border-radius: 14px;
// box-shadow:  8px 8px 16px #dfdeddu,
//              -8px -8px 16px #ffffff;
&:focus {
   box-shadow: 0 0 0 0; // $primaryColor;
  border-color:#ced4da;
  color: $primaryColor;
}
}
.input-group-text.search-button {
    cursor:pointer;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding:12px;
     transition: background-color 0.5s ease;
  &:hover {
  background:$primaryColor;
  color:$background;

  }

}

.clear-button {
    display:inline-block;
    cursor:pointer;
     transition: background-color 0.5s ease;
     transition: display 0.5s ease;
     background:$background;

}
.hidden {display:none}
}
