@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import "../../common/vars.scss";


.footer {
  margin-top:20px;

  font-family: 'Titillium Web', sans-serif;
  padding: 20px 0 0;
  padding-left:0;
   background: $searchHeading;
   color :$background;

   a { 
     color :$background;
      text-decoration: none;
      &:hover {
        text-decoration:underline;
      }
    }
h3 {
  margin-top:0;
  font-weight:bold;
  font-size:16px;
}

ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
  a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}
}
 .item.text {
  p {
  opacity:0.6;
  margin-bottom:0;
}
.right {
  text-align:right;
}

@media (max-width:767px) {
    margin-bottom:20px;
}
}
.copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}
.tcd {
  text-align:center;
  padding-bottom:4px;
  font-size:13px;
  margin-bottom:0;
   a:hover {
color:$displayUrl;
   }
}
.logo {
  margin-left:4px;
      font-size: 12px;
color: $background;
  font-family: 'Titillium Web', sans-serif;
  margin-top: -22px;
  margin-bottom:-10px;
}
@media (max-width:767px) {
 
    text-align:center;
    padding-bottom:20px;
  
}
};


 .footer.slim {
position:absolute;
width:100%;
  margin-top:20px;
  padding:0;
   background: $searchHeading;
   color :$background;
   a { 
     color :$background;
      text-decoration: none;
      &:hover {
        text-decoration:underline;
      }
    }
   }
