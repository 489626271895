@import "../../../common/vars.scss";

.logo {
      font-size: 62px;
color: $searchHeading;
  font-family: 'Titillium Web', sans-serif;
  margin-top: -22px;
  margin-bottom:-10px;
}

.tag-line {
display: block;
font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
     padding-left: 8px;
    .line1 {

      -webkit-animation-duration: 4s;
            animation-duration: 4s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    }

    .line2 {
      // font-style: italic;
 -webkit-animation-delay: 1s;
 animation-delay: 1s;
      -webkit-animation-duration: 4s;
            animation-duration: 4s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    }

}



@-webkit-keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
         
         @keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
.fade-in {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
         }
