@import '../../../../node_modules/bootstrap/scss/_functions.scss';
@import '../../../../node_modules/bootstrap/scss/_variables.scss';
@import '../../../../node_modules/bootstrap/scss/_mixins.scss';
@import '../../../common/vars.scss';

.tagBox { 
  margin-top:60px;
  margin-bottom:10px;
  background: $resultBox;
      padding: 20px;
    border-radius: 15px;
box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;
position:fixed;
width:40%;
  .title {
color: $primaryColor;
font-weight:700;
text-transform: uppercase;
}
.tagWrapper {

  height: calc(80vh - 34px);
  overflow-y:auto;
  overflow-x: hidden;
&::-webkit-scrollbar {
  width: 0.5em;
}
 
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
&::-webkit-scrollbar-thumb {
  background-color: $displayUrl;
  outline: 1px solid slategrey;
}
  .tag {
    border-radius: 50rem!important;
    background-color: $displayUrl;
    display: inline-block;
    padding: .35em .65em;
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin:3px;
    cursor: pointer;
      -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;

}

}
}


@-webkit-keyframes fadeIn {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
         
         @keyframes fadeIn {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
.fadeIn {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
         }
.tagBox> .tagWrapper >.tag.selected {
background:$searchHeading;;
    }

.tagBox> .tagWrapper >.tag.canSelect{

&:hover {
background:green;
  }
}


.tagBox> .tagWrapper >.tag.cantSelect{

&:hover {
background:$primaryColor;
  }
}


@include media-breakpoint-down (xl) {
.tagBox {
position:relative !important;
width:auto;
}
}
