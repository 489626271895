@import '../../../common/vars.scss';

.results-main {
 min-height :100vh ; 
.result-box{ 

  margin-top:34px;
  .web{
box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;
  cursor:pointer;
  margin-top:10px;
  margin-bottom:10px;
  background: $resultBox;
    border-radius: 15px;
 .title {
text-transform:uppercase;
color:$searchHeading;
font-weight:bold;
cursor:pointer;

&:hover {
color:$primaryColor;
}
}
.text {

    border-radius: 15px;
// box-shadow: 5px 8px 16px #dfdedd, -8px -8px 16px #ffffff;
  margin-top:10px;
  padding:10px;
  // background:#f5f5f5;
  cursor:auto;
color: #565656;
margin-bottom:10px;
 .entities {
   .entity {
     width: fit-content;
     margin:10px;
     background:#2b6fa9 ;
     border-radius: 15px;
     color:$background;
     text-transform:capitalize;
    .text2 {
      font-weight:500;
      margin-left:12px;
    }
  .type {

     font-style:italic;
      margin-right:12px;
  }
}
 }
}

.displayUrl {
color: $displayUrl;
margin-top:10px;
cursor:pointer;
&:hover {
color:$primaryColor;
}

}
.tab-bar {
  .button {
    display: inline-block;
    padding: 3px 18px;
    border-radius: 18px;
    margin-right: 10px;
     transition: background 0.5s ease;
    @media (max-width: 576px){ 

    padding: 3px 6px;
    }
  }
.active {
   background-color: #f4f4f4;
    color: #bdbdbd;
    cursor: default;
    background: #f4f4f4;
    box-shadow: inset 3px 3px 5px #cbcbcb, 
                inset -3px -3px 5px #ffffff;
}
.inactive {
      cursor: pointer;
      color: #7c7c7c;

    text-align: center;
    font-weight: 500;
    transition: background 0.1s, color 0.1s;
    background:  #fbfbfb;
    box-shadow:  3px 3px 5px #bebebe, 
                 -3px -3px 5px #ffffff;
  &:hover {
background: #eaeaea;
    color: #888;
  }
}
}
}

.news{
  margin-bottom:10px;
  padding:4px;
  background:  $background;
    border-radius: 8px;
    border: 1px solid;
  .heading {
padding-left:10px;
color:$primaryColor;
font-weight:bold;
  }
  .newsBox {
    margin:10px;
    padding:10px;
  background:#eeeeee;
  border-radius: 8px;
  cursor: pointer;
    img {
      float:left;
      padding-right:15px;
    }
 .title {
text-transform:uppercase;
font-weight: 500;
}
.text {
color: black;

}

.info {
  margin-top:10px;
  display: inline-block;
  // float:right;
  .org {
    // background: $secondaryColor;
    border-radius: 7px;
    /* padding-left: 7px; */
    padding: 3px;
    /* margin-top: 11px; */
    color:#286da8; 
    // img {
    //   float: right;
    // }
}

  .time {
  padding:10px;
  color:$primaryColor;

  }
  }

.displayUrl {
color: $displayUrl;

&:hover {
color:red;
}

}
}
button {
    background: $primaryColor;
    padding: 8px;
    margin-right: 8px;
    color:$background

}
}
.spell {
    color: #286da8;
    font-weight: 500;
    span {
        color:$primaryColor;
    &:hover {
      text-decoration: underline;
      color:$displayUrl;
      cursor: pointer;
    }
    }
}
      -webkit-animation-duration: 4s;
            animation-duration: 4s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

@-webkit-keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
         
         @keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
         }
.fade-in {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
         }
       }
