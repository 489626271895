
.info-page {
  .logo {
  a {

  cursor:pointer;
    text-decoration: none;
    &:hover {
    color: inherit;
    }
  }
}
}
