// $searchHeading : #76323F;
// $background: #ffffff; 
// $resultBox: #fbf9f8;
// $displayUrl: #C09F80;

$primaryColor: #76324F;
$secondaryColor: #286DA8;


$searchHeading : $secondaryColor;
$background: #ffffff; 
$resultBox: #fbf9f8;
$displayUrl: #C09F80;


// Global
body {

  background:$background;

}

