@import "../../common/vars.scss";

.early-adopters-button {
  all:unset;
  position: absolute;
  right: 12px;
  top:9px;
  border-radius: 4px;
  background:$secondaryColor;
  color:$background;
  color: white;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;

&:hover {
  
  background:$primaryColor;
} 
}


.join-button {
background:$primaryColor;
border-color: $primaryColor;

&:active{
   background:$primaryColor;

border-color: $primaryColor;
 }

&:focus{
   background:$primaryColor;

border-color: $primaryColor;
 }

&:hover {
  
background:$secondaryColor;

border-color: $primaryColor;
} 
}

.modal {
 background:$searchHeading

}
